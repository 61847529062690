import {
  Button,
  Form,
  Input,
  Row,
  Pagination,
  notification,
  DatePicker,
} from "antd";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/vi_VN";
import $axios from "../../services/axios";
import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";
// import trans from "../../data-test/test.json";

const History = ({ setStep, setLoading }) => {
  const [form] = Form.useForm();
  const [listHistory, setListHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangePresets = [
    {
      label: "7 ngày qua",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "14 ngày qua",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "30 ngày qua",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "90 ngày qua",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    getListHistory(currentPage, _.get(rangePresets, "[0].value", []));
  }, []);

  const getListHistory = async (page, dateRange) => {
    const startTime = dayjs(dateRange[0]).format("DD/MM/YYYY") + " 00:00:00";
    const endTime = dayjs(dateRange[1]).format("DD/MM/YYYY") + " 00:00:00";
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/transactions/list?startDate=${startTime}&endDate=${endTime}&page=${page}&pageSize=10`
      );
      setListHistory(
        _.map(res.data.data.data, (booking) => {
          booking.tripType = _.some(
            booking?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          )
            ? "roundTrip"
            : "oneWay";
          booking.listVexereBookingDepart = _.filter(
            booking?.listVexereBooking,
            (b) => b.ticketWay === 1
          );
          booking.listVexereBookingReturn = _.filter(
            booking?.listVexereBooking,
            (b) => b.ticketWay === 2
          );
          return booking;
        })
      );
      setTotal(res.data.data.totalRecordCount);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    try {
      await getListHistory(currentPage, values.dateRange);
    } catch (error) {
      notification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };
  const cancellingTicket = async (ticketCode) => {
    setLoading(true);
    try {
      const res = await $axios.$post(`/vexe/booking/refund`, {
        Code: ticketCode,
      });
      notification.success({
        message: "Thành công",
        description: "Huỷ vé thành công!",
      });
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Huỷ vé thất bại!",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderBooking = (bookings) => {
    return (
      <>
        <div className="flex justify-between mt-2 gap-2">
          <div className="flex flex-col items-start w-1/2 gap-2">
            <p className="text-[10px]">
              Chuyến đi :{" "}
              <span className="text-primary text-[10px] font-semibold">
                {bookings[0]?.tripName}
              </span>
            </p>
            <p className="text-[10px]">
              Điểm đón :{" "}
              <span className="text-primary text-[10px] font-semibold">
                {bookings[0]?.tripFrom}
              </span>
            </p>
            <p className="text-[10px]">
              Điểm đến :{" "}
              <span className="text-primary text-[10px] font-semibold">
                {bookings[0]?.tripTo}
              </span>
            </p>
          </div>
          <div className="flex flex-col items-start w-1/2 gap-1">
            <p className="text-[10px]">
              Thời gian:{" "}
              <span className="font-bold text-primary text-[10px] ">
                {moment(bookings[0]?.tripDate).format("HH:mm DD/MM/YYYY")}
              </span>
            </p>
            <p className="text-[10px]">
              Số lượng vé:{" "}
              <span className="font-bold text-primary text-[10px] ">
                {bookings.length}
              </span>
            </p>
            <p className="text-[10px]">
              Nhà xe :{" "}
              <span className="text-primary text-[10px] font-semibold">
                {bookings[0]?.providerName}
              </span>
            </p>
            {bookings.map((booking) => (
              <>
                <div className="flex items-center gap-3 mb-1">
                  <div className="text-[10px] flex-1 flex flex-col">
                    <div>
                      <span className="text-[10px]">Mã vé: </span>
                      <span className="text-yellow-600 text-[12px]">
                        {booking.ticketCode}{" "}
                      </span>
                    </div>
                    {booking?.bookingStatus === 1 ? (
                      <span className="text-green-500 text-[10px]">
                        Xuất thành công
                      </span>
                    ) : (
                      <span className="text-red-500 text-[10px]">
                        Xuất thất bại
                      </span>
                    )}
                  </div>
                  {/* {dayjs().isBefore(dayjs(booking.tripDate)) &&
                  booking.refundable ? (
                    <div
                      onClick={() => cancellingTicket(booking.ticketCode)}
                      className={`text-white bg-red-600 rounded-[20px] px-4 py-1 w-fit text-[10px]`}
                    >
                      Huỷ vé
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="flex items-center justify-center w-[92%] absolute left-1/2 transform -translate-x-1/2 top-[110px] pb-[24px]">
      <div className="flex flex-col gap-2 items-center w-full">
        <h2 className="text-white text-[16px] font-semibold mb-4">
          Lịch sử đặt vé
        </h2>
        <div className="w-full px-5 py-6 rounded-[12px] bg-white p-4">
          <Form
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              dateRange: rangePresets[0].value, // Giá trị mặc định cho `dateRange`
            }}
            className="flex items-start gap-2 m-0 bg-white"
          >
            {/* <Form.Item
              name={"keyword"}
              rules={[{ required: true, message: "" }]}
              className="flex-1 m-0"
            >
              <Input className="h-10" placeholder="Nhập từ khoá..." />
            </Form.Item> */}
            <Form.Item
              name={"dateRange"}
              rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
              className="flex-1 m-0"
            >
              <DatePicker.RangePicker
                popupClassName="ant-picker-no-left"
                presets={rangePresets}
                format="DD/MM/YYYY"
                className="h-10"
                locale={locale}
                suffixIcon={<img src={"/images/date.svg"} alt="" />}
              />
            </Form.Item>

            <Form.Item className="m-0">
              <Button
                variant="filled"
                color="#01AD53"
                htmlType="submit"
                className="bg-[#01ad53] h-10 font-bold text-white"
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
          {/* Hotline */}
        </div>
        <div className="w-full mt-[32px]">
          <div className="text-center my-4">
            <span className="text-[#808080] font-normal">
              Hotline hỗ trợ khách hàng{" "}
            </span>
            <a
              href="tel:0876100800"
              class="font-bold text-[#01ad53] text-[16px]"
            >
              0876.100.800
            </a>
          </div>
          {total > 0 && <p className="my-4 font-bold text-[#01ad53]">Vé xe</p>}
        </div>
        {/* {detail?.ErrorCode === "404" && (
            <h2 className="w-full text-primary font-semibold text-[18px] text-center absolute top-[480px] left-1/2 transform -translate-x-1/2 p-4">
              Không tìm thấy lịch sử đặt vé, vui lòng tìm kiếm lại
            </h2>
          )} */}
        {listHistory.length > 0 &&
          listHistory.map((item, index) => (
            <div
              key={item.id}
              className="mt-2 w-full p-4 bg-white rounded-[12px] mb-4 "
              style={{
                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="flex justify-between gap-1">
                <div className="flex flex-col items-start gap-1 w-1/2">
                  <p className="text-[12px]">
                    Mã đơn hàng :{" "}
                    <span className="text-orange-600 text-[10px] font-semibold">
                      {item.orderCode}
                    </span>
                  </p>

                  <p className="text-[12px]">
                    Loại vé :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.tripType === "roundTrip" ? "Khứ hồi" : "Một chiều"}
                    </span>
                  </p>

                  <p className="text-[12px]">
                    Tổng tiền :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.amount.toLocaleString("vi-VN")} đ
                    </span>
                  </p>
                  <div
                    className={`text-white ${
                      item.paymentStatus === 1 ? "bg-green-600" : "bg-red-600"
                    } rounded-[20px] px-4 py-1 w-fit text-[10px]`}
                  >
                    {item.paymentStatus === 1
                      ? "Đã thanh toán"
                      : "Chưa thanh toán"}
                  </div>
                </div>
                <div className="flex flex-col gap-1 w-1/2">
                  <p className="text-[12px]">
                    Họ và tên :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.listVexereBooking[0].customerName}
                    </span>
                  </p>
                  <p className="text-[12px]">
                    Số điện thoại :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.listVexereBooking[0].customerPhone}
                    </span>
                  </p>
                  <p className="text-[12px]">
                    Email :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.listVexereBooking[0].customerEmail}
                    </span>
                  </p>
                </div>
              </div>
              <>
                {item.tripType === "roundTrip" && (
                  <div className="text-[12px] font-bold mt-2">Chiều đi</div>
                )}
                {renderBooking(item.listVexereBookingDepart)}
                {item.tripType === "roundTrip" && <hr className="my-2" />}
                {item.tripType === "roundTrip" && (
                  <div className="text-[12px] font-bold mt-2">Chiều về</div>
                )}
                {item.tripType === "roundTrip" &&
                  renderBooking(item.listVexereBookingReturn)}
              </>
            </div>
          ))}

        {total > 0 && (
          <Pagination
            current={currentPage}
            className="mt-2"
            total={total}
            onChange={(page) => {
              setCurrentPage(page);
              getListHistory(page, form.getFieldValue("dateRange"));
            }}
            pageSize={10}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        )}

        {/* <div className="w-full px-4 mt-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                setStep("booking");
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#01ad53] border-[#01ad53] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default History;
