import {
  Button,
  Col,
  Form,
  Input,
  Row,
  notification,
  Checkbox,
  Drawer,
  Tabs,
  Pagination,
  Spin,
  Empty,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import CarItem from "./CarItem";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState, useRef } from "react";
import $axios from "../../services/axios";
import BookingJSON from "../../data-test/booking.json";
import InfoCar from "./InfoCar";
import _ from "lodash";
import dayjs from "dayjs";

const { TabPane } = Tabs;
const ChooseCar = ({
  carSelected,
  open,
  setOpenChooseCar,
  setCarSelected,
  area,
  addPrice = 0,
  tripType,
  filterSearch,
}) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const invoice = Form.useWatch("invoice", form);
  const [step, setStep] = useState(1);
  // NOTE: 4 step : 1,2,3,4
  // step === 1 : Chọn xe chiều đi
  // step === 2 : Chọn danh sách xe
  // step === 3 : Chọn xe chiều về
  // step === 4 : Điền form đặt xe
  const [openCarInfo, setOpenCarInfo] = useState(false);
  const [activeTabCarInfo, setActiveTabCarInfo] = useState("1");
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherTxt, setVoucherTxt] = useState("");
  const [bookingField, setBookingField] = useState("depart");
  const [loadingBooking, setLoadingBooking] = useState(false);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bookingInfo, setBookingInfo] = useState({
    depart: null,
    return: null,
  });
  const [carDepart, setCarDepart] = useState(null);
  const [carReturn, setCarReturn] = useState(null);
  const [listTripReturn, setListTripReturn] = useState([]);
  const [totalTripReturn, setTotalTripReturn] = useState(0);
  const [infoTripList, setInfoTripList] = useState({
    depart: null,
    return: null,
  });
  const [title, setTitle] = useState(
    tripType === "roundTrip" ? "1. CHỌN SỐ KHÁCH CHIỀU ĐI" : "1. CHỌN SỐ KHÁCH"
  );
  const [filterReturn, setFilterReturn] = useState({
    ...filterSearch,
    addStart: filterSearch.addEnd,
    addEnd: filterSearch.addStart,
  });

  useEffect(() => {
    if (carSelected && open) {
      setCarDepart(carSelected);
    }
  }, [carSelected, open]);

  useEffect(() => {
    if (step === 1) {
      setTitle(
        tripType === "roundTrip"
          ? "1. CHỌN SỐ KHÁCH CHIỀU ĐI"
          : "1. CHỌN SỐ KHÁCH"
      );
    } else if (step === 2) {
      setTitle("2. CHỌN XE CHIỀU VỀ");
    } else if (step === 3) {
      setTitle("3. CHỌN SỐ KHÁCH CHIỀU VỀ");
    } else {
      setTitle(`${tripType === "roundTrip" ? "4" : "2"}. THÔNG TIN KHÁCH HÀNG`);
    }
  }, [step, tripType]);

  const applyVoucher = async (e) => {
    if (e) {
      try {
        const res = await $axios.$get(`/voucher/getVoucher?voucherCode=${e}`);
        if (res.data.data.status === 1) {
          setVoucherValue(res.data.data.amount);
          setVoucherCode(e);
          setVoucherTxt(
            `<div class="text-[14px] ">Áp dụng mã giảm giá thành công! <span class="text-green-600 text-[16px]">
      ${res.data.data.amount.toLocaleString("vi-VN")} đ</span></div>`
          );
        } else {
          setVoucherTxt(
            `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
          );
          setVoucherValue(0);
          setVoucherCode("");
        }
      } catch (error) {
        setVoucherTxt(
          `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
        );
        setVoucherValue(0);
        setVoucherCode("");
      } finally {
      }
    } else {
      setVoucherTxt(
        `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
      );
      setVoucherValue(0);
      setVoucherCode("");
    }
  };

  const handleFindRouteReturn = async (filter) => {
    setLoading(true);
    setSearched(true);
    try {
      let urlFilter = `/vexe/routes?from=${filter.addStart}&to=${
        filter.addEnd
      }&date=${dayjs(filter.returnDate).format("YYYY-MM-DD")}&onlineTicket=${
        filter.onlineTicket
      }&ratingMin=${filter.ratingMin}&ratingMax=${filter.ratingMax}&timeMin=${
        filter.timeMin
      }&timeMax=${filter.timeMax}&fareMin=${filter.fareMin}&fareMax=${
        filter.fareMax
      }&availableSeatMin=${filter.availableSeatMin}&availableSeatMax=${
        filter.availableSeatMax
      }&sort=${filter.sort}&page=${filter.currentPage}&pageSize=20`;
      if (!_.isNil(filter.limousine)) {
        urlFilter += `&limousine=${filter.limousine}`;
      }
      if (!_.isNil(filter.isPromotion)) {
        urlFilter += `&isPromotion=${filter.isPromotion}`;
      }
      if (!_.isNil(filter.companies && filter.companies.length > 0)) {
        _.forEach(filter.companies, (c) => {
          urlFilter += `&companies=${c}`;
        });
      }

      const res = await $axios.$get(urlFilter);
      setListTripReturn(res.data.data.data);
      setTotalTripReturn(res.data.data.total);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại!",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const convertBodyBooking = (values) => {
    let bodys = [];
    _.forEach(bookingInfo, (booking, key) => {
      if (!_.isNil(booking)) {
        const body = {
          CountryCode: "VN",
          Seats: booking.seatChoosed.map((s) => s.full_code).join(","),
          TripCode:
            key === "depart"
              ? carDepart?.route?.schedules[0]?.trip_code
              : carReturn?.route?.schedules[0]?.trip_code,
          CustomerName: values.customer_name,
          CustomerPhone: values.customer_phone,
          CustomerEmail: values.customer_email,
          HaveEating: 0, // ăn hoặc không
          Note: values.customer_note,
          AreaId: Number(booking.addChoosed.start.areaDetail.id),
          CustomerIdNumber:
            infoTripList[key]?.company_id === 11071
              ? values.customer_id_number
              : "",
        };
        if (_.isNil(booking.addChoosed.start.is_transfer)) {
          // bình thường
          body.PickupId = booking.addChoosed.start.id;
          body.Pickup =
            booking.addChoosed?.start?.transfer_point ||
            booking.addChoosed.start.name;
        } else {
          body.TransferId = booking.addChoosed.start.id;
          body.Transfer =
            booking.addChoosed?.start?.transfer_point ||
            booking.addChoosed.start.name;
        }

        if (_.isNil(booking.addChoosed.end.is_transfer)) {
          // bình thường
          body.DropOffPointId = booking.addChoosed.end.id;
          body.DropOffInfo =
            booking.addChoosed?.end?.transfer_point ||
            booking.addChoosed?.end.name;
        } else {
          body.ArriveTransferId = booking.addChoosed.end.id;
          body.DropOffTransferInfo =
            booking.addChoosed?.end?.transfer_point ||
            booking.addChoosed?.end.name;
        }
        bodys.push(body);
      }
    });
    return tripType === "roundTrip" ? bodys : _.first(bodys);
  };

  const convertBodyPaymentRequired = (bodyBooking, values, resBooking) => {
    const resBookingDepart =
      tripType === "roundTrip" ? resBooking[0] : resBooking;
    const ListBookingDepart = bookingInfo.depart.seatChoosed.map((s, index) => {
      return {
        CustomerEmail: bodyBooking[0].CustomerEmail,
        CustomerName: bodyBooking[0].CustomerName,
        CustomerPhone: bodyBooking[0].CustomerPhone,
        TripCode: bodyBooking[0].TripCode,
        TripName: `${area?.from?.name} - ${area?.to?.name}`,
        TripDate: infoTripList.depart?.departure_time,
        TripFrom: bookingInfo.depart?.addChoosed.start.name,
        TripFromCode: area?.from.code,
        TripTo: bookingInfo.depart?.addChoosed.end.name,
        TripToCode: area?.to.code,
        Note: bodyBooking[0].Note,
        CountryCode: "VN",
        AreaId: bodyBooking[0].AreaId,
        HaveEating: bodyBooking[0].HaveEating,
        BookingCode: resBookingDepart.booking_code,
        TicketCode: resBookingDepart.tickets[index],
        TicketWay: 1, //  1: chiều đi , 2 : chiều về
        Amount: s.newFare,
        AmountOriginal: s.fare,
        AmountDiscount: 0,
        Seats: _.get(carDepart, "route.schedules[0].unchoosable", false)
          ? ""
          : s.full_code,
        ProviderName: infoTripList?.depart?.company_name,
        ProviderCode: infoTripList?.depart?.company_id,
        ProviderTypeCar: _.get(
          carDepart,
          "route.schedules[0].vehicle_type",
          ""
        ),
        ProviderTypeCarCode: infoTripList?.depart?.company_id,
        SeatGroupCode: s.seat_group_code,
        SeatGroup: s.seat_group,
        Refundable: _.get(carDepart, "route.schedules[0].refundable", 0),
      };
    });

    let ListBookingReturn = [];
    if (tripType === "roundTrip") {
      const resBookingReturn = resBooking[1];
      ListBookingReturn = bookingInfo.return.seatChoosed.map((s, index) => {
        return {
          CustomerEmail: bodyBooking[1].CustomerEmail,
          CustomerName: bodyBooking[1].CustomerName,
          CustomerPhone: bodyBooking[1].CustomerPhone,
          TripCode: bodyBooking[1].TripCode,
          TripName: `${area?.to?.name} - ${area?.from?.name}`,
          TripDate: infoTripList.return?.departure_time,
          TripFrom: bookingInfo.return?.addChoosed.start.name,
          TripFromCode: area?.from.code,
          TripTo: bookingInfo.return?.addChoosed.end.name,
          TripToCode: area?.to.code,
          Note: bodyBooking.Note,
          CountryCode: "VN",
          AreaId: bodyBooking[1].AreaId,
          HaveEating: bodyBooking[1].HaveEating,
          BookingCode: resBookingReturn.booking_code,
          TicketCode: resBookingReturn.tickets[index],
          TicketWay: 2, // , 1: chiều đi , 2 : chiều về
          Amount: s.newFare,
          AmountOriginal: s.fare,
          AmountDiscount: 0,
          Seats: _.get(carReturn, "route.schedules[0].unchoosable", false)
            ? ""
            : s.full_code,
          ProviderName: infoTripList?.return?.company_name,
          ProviderCode: infoTripList?.return?.company_id,
          ProviderTypeCar: _.get(
            carDepart,
            "route.schedules[0].vehicle_type",
            ""
          ),
          ProviderTypeCarCode: infoTripList?.depart?.company_id,
          SeatGroupCode: s.seat_group_code,
          SeatGroup: s.seat_group,
          Refundable: _.get(carReturn, "route.schedules[0].refundable", 0),
        };
      });
    }

    const body = {
      Amount:
        tripType === "roundTrip"
          ? bookingInfo.depart.totalPrice +
            bookingInfo.return.totalPrice -
            voucherValue
          : bookingInfo.depart.totalPrice - voucherValue,
      AmountOriginal:
        tripType === "roundTrip"
          ? bookingInfo.depart.totalPrice + bookingInfo.return.totalPrice
          : bookingInfo.depart.totalPrice,
      AmountDiscount: voucherValue,
      VoucherCode: voucherCode,
      CustomerName: values.customer_name,
      CustomerPhone: values.customer_phone,
      CustomerEmail: values.customer_email,
      ListBooking:
        tripType === "roundTrip"
          ? ListBookingDepart.concat(ListBookingReturn)
          : ListBookingDepart,
    };
    if (invoice) {
      body.VatMst = values.VatMst;
      body.VatEmail = values.customer_email;
      body.VatFullName = values.VatFullName;
    }
    return body;
  };

  const handleBooking = async (values) => {
    try {
      setLoadingBooking(true);
      const bodyBooking = convertBodyBooking(values);
      const res = await $axios.$post(
        `/vexe/booking/${tripType === "roundTrip" ? "two-way" : "one-way"}`,
        bodyBooking
      );
      if (res.data.statusCode === 200) {
        const bodyPaymentRequired = convertBodyPaymentRequired(
          tripType === "roundTrip" ? bodyBooking : [bodyBooking],
          values,
          tripType === "roundTrip" ? res.data.data : res.data.data.data
        );
        const resPayment = await $axios.post(
          "/payment/paymentRequired",
          bodyPaymentRequired
        );
        if (resPayment.data.data.orderId) {
          // window.location.href = resPayment.data.data.paymentUrl;
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.NativeJavascriptInterface
          ) {
            // Chuyển đổi dữ liệu thành chuỗi JSON và gửi qua Swift
            window.webkit.messageHandlers.NativeJavascriptInterface.postMessage({
              orderCode: resPayment.data.data.orderId,
            });
          } else {
            console.error(
              "NativeJavascriptInterface không tồn tại hoặc không được hỗ trợ."
            );
          }
          // Send orderCode for Android
          if (window.webkit !== undefined) {
            if (window.webkit.messageHandlers.appInterface !== undefined) {
              window.webkit.messageHandlers.appInterface.postMessage(
                JSON.stringify({
                  orderCode: resPayment.data.data.orderId,
                })
              );
            }
          }
          if (window.appInterface !== undefined) {
            window.appInterface.postMessage(
              JSON.stringify({
                orderCode: resPayment.data.data.orderId,
              })
            );
          }
        } else {
          notification.error({
            status: "error",
            message: "Thất bại",
            description: "Đã có lỗi xảy ra! Vui lòng đặt chuyến khác",
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
      notification.error({
        status: "error",
        message: "Thất bại",
        description: error?.response?.data?.message || "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoadingBooking(false);
    }
  };

  const handleValidateChooseSeat = () => {
    if (
      (bookingInfo[bookingField].addChoosed.start.unfixed_point === 1 &&
        !bookingInfo[bookingField].addChoosed.start.transfer_point) ||
      (bookingInfo[bookingField].addChoosed.end.unfixed_point === 1 &&
        !bookingInfo[bookingField].addChoosed.end.transfer_point)
    ) {
      notification.error({
        description: "Bạn chưa nhập điểm đón/trung chuyển đón",
      });
      return;
    }

    if (step === 1 && tripType === "roundTrip") {
      setStep(2);
      handleFindRouteReturn(filterReturn);
    } else {
      setStep(4);
    }
  };

  const handleOkNotValidTrip = () => {
    setOpenChooseCar(false);
    setCarSelected(null);
  };

  return (
    <>
      <Drawer
        title={title}
        placement={"bottom"}
        closable={true}
        onClose={() => {
          setOpenChooseCar(false);
          setCarSelected(null);
        }}
        open={open}
        width="100%"
        footer={
          <>
            {(step === 1 || step === 3) && (
              <>
                {bookingInfo[bookingField]?.addChoosed?.start &&
                bookingInfo[bookingField]?.addChoosed?.end &&
                bookingInfo[bookingField]?.seatChoosed?.length > 0 ? (
                  <div className="flex justify-between">
                    <div className="flex flex-col gap-2">
                      <div>
                        {bookingInfo[bookingField].seatChoosed.length} Khách{" "}
                        {!_.get(
                          step === 1 ? carDepart : carReturn,
                          "route.schedules[0].unchoosable",
                          0
                        ) &&
                          ":" +
                            bookingInfo[bookingField].seatChoosed
                              .map((s) => s.seat_code)
                              .join(",")}
                      </div>
                      <div>
                        Tổng tiền:{" "}
                        <span className="text-[#01ad53] text-[20px] font-semibold">
                          {_.sum([
                            bookingInfo[bookingField].totalNetPrice,
                            bookingInfo[bookingField].totalSurcharge,
                          ]).toLocaleString("vi-VN")}{" "}
                          đ
                        </span>
                      </div>
                    </div>
                    <Button
                      variant="filled"
                      color="#01AD53"
                      className="w-[150px] h-[50px] bg-[#01ad53]  text-white"
                      onClick={() => handleValidateChooseSeat()}
                    >
                      <span className="text-[16px] text-white">Tiếp tục</span>{" "}
                      <FontAwesomeIcon icon={faAngleRight} color="white" />
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex items-center justify-center w-full">
                      <FontAwesomeIcon
                        icon={faAngleDoubleUp}
                        color="#01ad53"
                        onClick={() => setOpenCarInfo(true)}
                        className="w-full h-5"
                      />
                    </div>
                    <Tabs
                      className="w-full tabs-no-bottom"
                      onChange={(e) => {
                        setOpenCarInfo(true);
                        setActiveTabCarInfo(e);
                      }}
                      size="small"
                      activeKey={activeTabCarInfo}
                    >
                      <TabPane tab="Hình ảnh" key="1" className="px-0" />
                      <TabPane tab="Phí huỷ" key="2" className="px-0" />
                      <TabPane tab="Đánh giá" key="3" className="px-0" />
                      <TabPane tab="Chính sách" key="4" className="px-0" />
                    </Tabs>
                  </div>
                )}
              </>
            )}
            {step === 4 && (
              <div className="flex justify-between gap-5">
                {/* <Button
                  className="w-full h-[50px] bg-white  text-[#01ad53]"
                  onClick={() => setStep(3)}
                >
                  <FontAwesomeIcon icon={faAngleLeft} color="#01ad53" />
                  <span className="text-[16px]">Quay lại</span>{" "}
                </Button> */}
                <Button
                  className="w-full h-[50px] bg-[#01ad53]  text-white"
                  loading={loadingBooking}
                  onClick={() => {
                    formRef.current.submit();
                  }}
                  variant="filled"
                  color="#01AD53"
                >
                  <span className="text-[16px]">Thanh toán</span>{" "}
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                </Button>
              </div>
            )}
          </>
        }
        height={"100%"}
      >
        <Spin
          tip={
            <div>
              <div className="text-[16px] text-[#01ad53]">Tải dữ liệu</div>
            </div>
          }
          size="large"
          spinning={loading}
          indicator={
            <LoadingOutlined
              size={"large"}
              style={{ fontSize: 32, color: "#01ad53" }}
              spin
            />
          }
        >
          {step > 1 && (
            <div className="w-[67px] ml-0 mb-4">
              <Button
                className="text-[14px] text-[#01ad53]"
                icon={<FontAwesomeIcon icon={faAngleLeft} color="#01ad53" />}
                onClick={() => {
                  setStep(tripType === "roundTrip" ? step - 1 : 1);
                }}
              >
                Quay lại
              </Button>
            </div>
          )}
          {(step === 1 || step === 3) && (
            <InfoCar
              carSelected={step === 1 ? carDepart : carReturn}
              addPrice={addPrice}
              bookingField={bookingField}
              setBookingInfo={setBookingInfo}
              bookingInfo={bookingInfo}
              handleOkNotValidTrip={handleOkNotValidTrip}
              openCarInfo={openCarInfo}
              setOpenCarInfo={setOpenCarInfo}
              setInfoTripList={setInfoTripList}
              infoTripList={infoTripList}
              setLoading={setLoading}
              activeTabCarInfo={activeTabCarInfo}
              setActiveTabCarInfo={setActiveTabCarInfo}
              loading={loading}
            />
          )}
          {step === 2 && (
            <>
              {listTripReturn && listTripReturn.length > 0 ? (
                <div className="flex flex-col">
                  <div className="mt-4 w-full">
                    {listTripReturn.map((item, index) => (
                      <CarItem
                        key={index}
                        setOpenChooseCar={setOpenChooseCar}
                        data={item}
                        setCarSelected={(e) => {
                          setCarReturn(e);
                          setStep(3);
                          setBookingField("return");
                        }}
                        addPrice={addPrice}
                        area={area}
                      />
                    ))}
                  </div>
                  <Pagination
                    current={filterReturn.currentPage}
                    className="my-4 w-full justify-center flex"
                    total={totalTripReturn}
                    onChange={(page) => {
                      const _filter = { ...filterReturn, currentPage: page };
                      setFilterReturn(_filter);
                      handleFindRouteReturn(_filter);
                    }}
                    pageSize={20}
                    showSizeChanger={false}
                    showQuickJumper={false}
                  />
                </div>
              ) : (
                <>
                  {!loading && searched && (
                    <div className="flex flex-col w-full items-center mt-[48px]">
                      <div className="text-[24px] text-[#01ad53] font-bold text-center mb-3">
                        Không tìm thấy chuyến trên vBooking
                      </div>
                      <div className="mb-4 text-center text-[#01ad53]">
                        Hiện tại <span className="font-bold">vBooking</span> chưa
                        cập nhật đơn hàng của tuyến{" "}
                        <span className="font-bold">
                          {area?.to?.name} - {area?.from?.name} ngày{" "}
                          {dayjs(filterReturn.returnDate).format("DD-MM-YYYY")}
                        </span>
                      </div>
                      <div className="mb-4 text-center text-[#01ad53]">
                        Xin quý khách vui lòng tìm chuyến với ngày khởi hành
                        khác hoặc thay đổi tuyến đường tìm kiếm.
                      </div>
                      <Empty description={""} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {step === 4 && (
            <div>
              <Form
                layout="vertical"
                form={form}
                ref={formRef}
                onFinish={handleBooking}
                onFinishFailed={(values) => {
                  notification.error({
                    message: "Thông báo",
                    description: "Vui lòng nhập đầy đủ thông tin",
                  });
                }}
                autoComplete="off"
                className="form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      name="customer_name"
                      className="mb-4"
                      rules={[
                        {
                          required: true,
                          message: "Họ tên không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Họ Tên" className="h-12" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="customer_phone"
                      rules={[
                        {
                          required: true,
                          message: "Số điện thoại không được để trống",
                        },
                        {
                          pattern: /^(03|05|07|08|09)\d{8}$|^\+84\d{9}$/,
                          message: "Số điện thoại không hợp lệ",
                        },
                      ]}
                      className="mb-4"
                    >
                      <Input placeholder="SĐT: 0912345678" className="h-12" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="customer_email"
                  className="mb-4"
                  rules={[
                    {
                      required: true,
                      message: "Email không được để trống",
                    },
                    {
                      type: "email",
                      message: "Vui lòng nhập email đúng định dạng",
                    },
                  ]}
                >
                  <Input placeholder="Email" className="h-12" />
                </Form.Item>
                {(infoTripList?.depart?.company_id === 11071 ||
                  infoTripList?.return?.company_id === 11071) && (
                  <Form.Item name="customer_id_number" className="mb-4">
                    <Input placeholder="Chứng minh nhân dân" className="h-12" />
                  </Form.Item>
                )}
                {/* <Form.Item name="customer_address" className="mb-4">
              <Input placeholder="Nơi ở hiện nay" className="h-12" />
            </Form.Item> */}
                <Form.Item name="customer_note" className="mb-4">
                  <Input.TextArea
                    placeholder="Các yêu cầu đặc biệt không thể được đảm bảo - nhưng nhà xe sẽ cố gắng hết sức để đáp ứng nhu cầu của bạn."
                    rows={3}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="invoice"
                  className="mb-3"
                  valuePropName="checked"
                >
                  <Checkbox>Xuất hoá đơn</Checkbox>
                </Form.Item>
                {invoice && (
                  <>
                    <Row gutter={8}>
                      <Col span={12}>
                        <Form.Item
                          name="VatMst"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Mã số thuế không được để trống",
                            },
                          ]}
                        >
                          <Input className="h-12" placeholder="Mã số thuế..." />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="VatFullName"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Người nhận hoá đơn không được để trống",
                            },
                          ]}
                        >
                          <Input
                            className="h-12"
                            placeholder="Nhập người nhận hoá đơn..."
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Form.Item name="voucher" className="w-full mb-0">
                  <Input.Search
                    placeholder="Nhập mã giảm giá"
                    size="large"
                    enterButton={
                      <Button
                        variant="filled"
                        color="#01AD53"
                        style={{
                          backgroundColor: "#01ad53",
                          borderColor: "#01ad53",
                          color: "#FFFFFF",
                          fontWeight: "bold",
                        }}
                      >
                        <span className="text-white"> Áp dụng</span>
                      </Button>
                    }
                    allowClear
                    onChange={(e) => {
                      if (!e.target.value) {
                        setVoucherTxt("");
                        setVoucherValue(0);
                      }
                    }}
                    onSearch={applyVoucher}
                  />
                </Form.Item>
                {voucherTxt && (
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{ __html: voucherTxt }}
                  />
                )}
              </Form>
              <div className="p-2 bg-[#e7ffe5] text-[#7bc27b] rounded my-4">
                Vui lòng cung cấp email và số điện thoại Việt Nam của người đi.
              </div>

              {(tripType === "roundTrip"
                ? ["depart", "return"]
                : ["depart"]
              ).map((type) => (
                <div key={type}>
                  {tripType === "roundTrip" && (
                    <div className="text-[16px] font-bold text-left mt-4 text-[#01ad53]">
                      {type === "depart" ? "Chiều đi" : "Chiều về"}
                    </div>
                  )}

                  <div className="flex flex-col items-center gap-2 my-5">
                    <div className="font-semibold">
                      {infoTripList[type]?.company_name}
                    </div>
                    <div className="text-[12px] italic">
                      {infoTripList[type]?.departure_time.split(" ")[0]}{" "}
                      {infoTripList[type]?.departure_time.split(" ")[1]} •{" "}
                      {type === "depart" ? (
                        <span>
                          {" "}
                          {area?.from?.name} - {area?.to?.name}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {area?.to?.name} - {area?.from?.name}
                        </span>
                      )}
                    </div>
                  </div>

                  {!_.get(
                    type === "depart" ? carDepart : carReturn,
                    "route.schedules[0].unchoosable",
                    0
                  ) && (
                    <Row className="mb-2">
                      <Col span={8}>
                        <div>Khách: </div>
                      </Col>
                      <Col span={16}>
                        <div className="font-semibold text-right">
                          {bookingInfo[type].seatChoosed
                            .map((s) => s.seat_code)
                            .join(",")}
                        </div>
                      </Col>
                    </Row>
                  )}

                  {infoTripList[type].company_id === 11071 && (
                    <Row className="mb-[32px]">
                      <Col span={8}>
                        <div>Phí dịch vụ: </div>
                      </Col>
                      <Col span={16}>
                        <div className="text-right font-semibold text-[20px] text-[#01ad53]">
                          {" "}
                          {infoTripList[type]?.service_fee.toLocaleString(
                            "vi-VN"
                          )}{" "}
                          đ
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-[32px]">
                    <Col span={8}>
                      <div>Tổng tiền: </div>
                    </Col>
                    <Col span={16}>
                      <div className="text-right font-semibold text-[20px] text-[#01ad53]">
                        {" "}
                        {(tripType === "roundTrip"
                          ? bookingInfo[type].totalPrice
                          : bookingInfo[type].totalPrice - voucherValue
                        ).toLocaleString("vi-VN")}{" "}
                        đ
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={8}>
                      <div>Điểm đi: </div>
                    </Col>
                    <Col span={16}>
                      <div className="flex flex-col">
                        <div className="text-[13px] text-right font-semibold">
                          {bookingInfo[type].addChoosed.start.name}{" "}
                        </div>
                        <div className="text-[13px] text-right italic">
                          {bookingInfo[type].addChoosed.start?.transfer_point
                            ? [
                                bookingInfo[type].addChoosed.start
                                  ?.transfer_point || "",
                                bookingInfo[type].addChoosed.start.address,
                              ].join(", ")
                            : bookingInfo[type].addChoosed.start.address}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={8}>
                      <div>Điểm đến: </div>
                    </Col>
                    <Col span={16}>
                      <div className="flex flex-col">
                        <div className="text-[13px] text-right font-semibold">
                          {bookingInfo[type].addChoosed.end.name}{" "}
                        </div>
                        <div className="text-[13px] text-right italic">
                          {bookingInfo[type].addChoosed.end?.transfer_point
                            ? [
                                bookingInfo[type].addChoosed.end
                                  ?.transfer_point || "",
                                bookingInfo[type].addChoosed.end.address,
                              ].join(", ")
                            : bookingInfo[type].addChoosed.end.address}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}

              {tripType === "roundTrip" && (
                <div className="flex justify-between items-center mt-[24px]">
                  <span className="text-[18px]  font-bold">Tổng Tiền</span>
                  <span className="text-red-700 text-[22px]  font-bold">
                    {(
                      _.sum([
                        bookingInfo.depart.totalPrice,
                        bookingInfo.return.totalPrice,
                      ]) - voucherValue
                    ).toLocaleString("vi-VN")}{" "}
                    đ
                  </span>
                </div>
              )}
            </div>
          )}
        </Spin>
      </Drawer>
    </>
  );
};

export default ChooseCar;
