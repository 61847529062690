import React, { useState, useEffect } from "react";
// import BgSuccess from "../../images/bg-success.svg";
import { Button, notification, Descriptions } from "antd";
import _ from "lodash";
import $axios from "../../services/axios";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const PaymentResult = ({ setLoading, loading }) => {
  const location = useLocation();
  const history = useHistory();
  const [statusPayment, setStatusPayment] = useState(true);
  const [bookingTicket, setBookingTicket] = useState(null);

  useEffect(() => {
    handleBooking();
  }, []);

  const handleBooking = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");
    const messageVtc = queryParams.get("message");
    setStatusPayment(status === "1");
    try {
      const body = {
        OrderId: reference_number,
        Amount: amount,
        Message: messageVtc,
        Status: Number(status),
        PaymentType: payment_type,
        ReferenceNumber: reference_number,
        TransRefNo: trans_ref_no,
        WebsiteId: website_id,
        Signature: signature,
      };
      await $axios.$post("/payment/paymentHandle", body);
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: error?.response?.data?.message || "Đã có lỗi xảy ra!",
      });
    } finally {
      if (status === "1") {
        const res = await $axios.$get(
          `/transactions/details?orderCode=${reference_number}`
        );
        setBookingTicket(res.data.data.data);
      }
      setLoading(false);
      setTimeout(() => {
        const { pathname } = location;
        history.replace(pathname);
      }, 2000);
    }
  };

  const items = [
    {
      key: "customerName",
      label: "Họ tên",
      children: bookingTicket?.listVexereBooking[0]?.customerName || "",
    },
    {
      key: "customerPhone",
      label: "Số điện thoại",
      children: bookingTicket?.listVexereBooking[0]?.customerPhone,
    },
    {
      key: "customerEmail",
      label: "Email",
      children: bookingTicket?.listVexereBooking[0]?.customerEmail,
    },
    {
      key: "tripName",
      label: "Chuyến đi",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.tripName
              }
              <div>Chiều về: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.tripName
              }
            </>
          ) : (
            <span>{bookingTicket?.listVexereBooking[0].tripName}</span>
          )}
        </>
      ),
    },
    {
      key: "tripName",
      label: "Thời gian",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {moment(
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.tripDate
              ).format("HH:mm DD/MM/YYYY")}
              <div>Chiều về: </div>
              {moment(
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.tripDate
              ).format("HH:mm DD/MM/YYYY")}
            </>
          ) : (
            <span>
              {moment(bookingTicket?.listVexereBooking[0].tripDate).format(
                "HH:mm DD/MM/YYYY"
              )}
            </span>
          )}
        </>
      ),
    },
    {
      key: "tripFrom",
      label: "Điểm đón",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.tripFrom
              }
              <div>Chiều về: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.tripFrom
              }
            </>
          ) : (
            <span>{bookingTicket?.listVexereBooking[0].tripFrom}</span>
          )}
        </>
      ),
    },
    {
      key: "tripTo",
      label: "Điểm đến",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.tripTo
              }
              <div>Chiều về: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.tripTo
              }
            </>
          ) : (
            <span>{bookingTicket?.listVexereBooking[0].tripTo}</span>
          )}
        </>
      ),
    },
    {
      key: "VAT",
      label: "VAT",
      children: (
        <>
          {bookingTicket?.vatFullName ? (
            <>
              Mã số thuế: {bookingTicket?.vatMst}
              <br />
              Họ tên: {bookingTicket?.vatFullName}
              <br />
              Email: {bookingTicket?.vatEmail}
            </>
          ) : (
            "Không"
          )}
        </>
      ),
    },
    {
      key: "twoWay",
      label: "Loại vé",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          )
            ? "Khứ hồi"
            : "Một chiều"}
        </>
      ),
    },
    {
      key: "providerName",
      label: "Nhà xe",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.providerName
              }
              <div>Chiều về: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.providerName
              }
            </>
          ) : (
            <span>{bookingTicket?.listVexereBooking[0].providerName}</span>
          )}
        </>
      ),
    },
    {
      key: "providerTypeCar",
      label: "Loại xe",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 1
                )?.providerTypeCar
              }
              <div>Chiều về: </div>
              {
                _.find(
                  bookingTicket?.listVexereBooking,
                  (b) => b.ticketWay === 2
                )?.providerTypeCar
              }
            </>
          ) : (
            <span>{bookingTicket?.listVexereBooking[0].providerTypeCar}</span>
          )}
        </>
      ),
    },
    {
      key: "bookingCode",
      label: "Mã đặt chỗ",
      children: <>{bookingTicket?.listVexereBooking[0].bookingCode}</>,
    },
    {
      key: "seatGroup",
      label: "Loại chỗ",
      children: <>{bookingTicket?.listVexereBooking[0].seatGroup}</>,
    },
    {
      key: "numTicket",
      label: "Số lượng vé",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {_.filter(
                bookingTicket?.listVexereBooking,
                (b) => b.ticketWay === 1
              ).length + "vé"}
              <div>Chiều về: </div>
              {_.filter(
                bookingTicket?.listVexereBooking,
                (b) => b.ticketWay === 2
              ).length + "vé"}
            </>
          ) : (
            <>{bookingTicket?.listVexereBooking.length} vé</>
          )}
        </>
      ),
    },
    {
      key: "ticketCode",
      label: "Mã ghế",
      children: (
        <>
          {_.some(
            bookingTicket?.listVexereBooking,
            (ticket) => ticket.ticketWay === 2
          ) ? (
            // Khứ hồi
            <>
              <div>Chiều đi: </div>
              {_.filter(
                bookingTicket?.listVexereBooking,
                (b) => b.ticketWay === 1
              ).map((item) => (
                <>
                  <span>{item.seats.split("|")[0]}</span>
                  <br />
                </>
              ))}
              <div>Chiều về: </div>
              {_.filter(
                bookingTicket?.listVexereBooking,
                (b) => b.ticketWay === 2
              ).map((item) => (
                <>
                  <span>{item.seats.split("|")[0]}</span>
                  <br />
                </>
              ))}
            </>
          ) : (
            <>
              {bookingTicket?.listVexereBooking.map((item) => (
                <>
                  <span>
                    <span>{item.seats.split("|")[0]}</span>
                  </span>
                  <br />
                </>
              ))}
            </>
          )}
        </>
      ),
    },
    {
      key: "voucher",
      label: "Giảm giá",
      children: (
        <div className="text-[16px] text-[#01ad53]">
          {bookingTicket?.amountDiscount?.toLocaleString("vi-VN")} đ
        </div>
      ),
    },
    {
      key: "amount",
      label: "Tổng tiền",
      children: (
        <div className="text-[16px] text-red-700 font-semibold">
          {bookingTicket?.amount?.toLocaleString("vi-VN")} đ
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col items-center pt-[24px]">
      <div className="w-[90%]">
        <div className="w-full">
          {/* <img src={BgSuccess} alt="" className="w-full" /> */}
          <div className="flex flex-col justify-center gap-4">
            {!loading && (
              <>
                {statusPayment ? (
                  <div className="flex flex-col p-4 bg-white rounded-lg">
                    <div className="text-[24px] text-[#00AB56] font-bold text-center mb-4 uppercase">
                      Thanh toán thành công
                    </div>
                    {!_.isNil(bookingTicket) && (
                      <Descriptions
                        title="Thông tin chuyến đi"
                        bordered
                        items={items}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col p-4 bg-white rounded-lg">
                    <div className="text-[24px] text-[#D72F20] font-bold text-center uppercase">
                      Thanh toán thất bại
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="w-full px-4 mt-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                window.location.href = "/booking-taxi";
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#01ad53] border-[#01ad53] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentResult;
